import * as React from 'react';
import { clsx as cx } from 'clsx';

import { EPlatform } from '../../types/platform';
import { TFooterType } from '../../types/footerType';

import * as styles from './MobileFooter.css';
import { CianLogo } from '../CianLogo';
import { DownloadAppBanner } from './DownloadAppBanner';

interface IMobileFooterProps {
  platform: EPlatform | null;
  type: TFooterType;
  withSiteTypeChanging: boolean;
  isDownloadAppBannerDisabled: boolean;
  isMobilePromoCianAppsExperimentEnabled: boolean;
  onSiteTypeChange(event: React.MouseEvent<HTMLAnchorElement>): void;
  onDownloadAppClick(event: React.MouseEvent<HTMLAnchorElement>, platform: EPlatform): void;
  onMobileAppClick(): void;
}

export const MobileFooter: React.FC<IMobileFooterProps> = params => {
  const {
    platform,
    type,
    withSiteTypeChanging,
    onSiteTypeChange,
    onDownloadAppClick,
    onMobileAppClick,
    isDownloadAppBannerDisabled,
    isMobilePromoCianAppsExperimentEnabled,
  } = params;

  return (
    <div className={cx(styles['footer-mobile'], type === 'adaptive' && styles['footer-mobile--adaptive'])}>
      {!isDownloadAppBannerDisabled && platform && (
        <DownloadAppBanner
          platformType={platform}
          onDownloadAppClick={onDownloadAppClick}
          isMobilePromoCianAppsExperimentEnabled={isMobilePromoCianAppsExperimentEnabled}
          onMobileAppClick={onMobileAppClick}
        />
      )}
      <CianLogo />
      {withSiteTypeChanging && (
        <a href="/" className={styles['full-link']} data-testid="LinkToFullVersion" onClick={onSiteTypeChange}>
          Полная версия сайта
        </a>
      )}
    </div>
  );
};
