import * as React from 'react';
import { useSelector } from 'react-redux';

import { CianGroup } from '../../components/CianGroup';
import { trackDownloadAppClick, trackSiteTypeChange, trackMobileAppClick } from './tracking';
import { selectEventCategory } from '../../selectors/eventCategory';
import { EAppService } from '../../types/appService';
import { IApplicationState } from '../../types/redux';
import { useApplicationContext } from '../../utils/applicationContext';

export const CianGroupContainer: React.FC = () => {
  const { config } = useApplicationContext();
  const showSiteTypeChanging = useSelector((state: IApplicationState) => state.showSiteTypeChanging);
  const eventCategory = useSelector(selectEventCategory);
  const ageRestriction = config.get<number>('frontendFooter.ageRestriction') ?? 0;

  const handleDownloadAppClick = (event: React.MouseEvent<HTMLAnchorElement>, appService: EAppService) => {
    trackDownloadAppClick({ category: eventCategory, appService });
  };

  const handleSiteTypeChange = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    trackSiteTypeChange(eventCategory);

    document.cookie = 'flavour=mobile;path=/;domain=.cian.ru';
    location.reload();
  };

  const handleMobileAppClick = () => {
    trackMobileAppClick();
  };

  return (
    <CianGroup
      withSiteTypeChanging={showSiteTypeChanging}
      onDownloadAppClick={handleDownloadAppClick}
      onSiteTypeChange={handleSiteTypeChange}
      onMobileAppClick={handleMobileAppClick}
      ageRestriction={ageRestriction}
    />
  );
};
